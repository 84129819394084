import React, {Component} from "react";
import Layout from "../components/Layout/Layout";
import SEO from "../components/Core/Seo";
import { Container } from "styled-bootstrap-grid";
import ContentContainer from "../components/Core/ContentContainer";
import styled from 'styled-components';
import breakpoints from "../styles/breakpoints"
import LightTitle from "../components/Core/LightTitle"
import colors from "../styles/colors"
import fonts from "../styles/fonts"

const ContainerStyled = styled(Container)`
  margin-top: 55px;
  
  @media(min-width: ${breakpoints.sm}){
    margin-top: 130px;
  }
`;

const Error = styled.h1`
  font-size: 14px;
  line-height: 30px;
  display: block;
  text-decoration: none;
  color: ${colors.darkGrey};
  ${fonts.ralewayMedium};
  letter-spacing: 0.28px;
`;

class NotFoundPage extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Page not found."
             description="The page you are looking for has been removed or relocated."/>
             <ContainerStyled>
               <ContentContainer>
                 <LightTitle>404</LightTitle>
                 <Error>The page you are looking for has been removed or relocated.</Error>
               </ContentContainer>
             </ContainerStyled>
      </Layout>
    );
  }
}

export default NotFoundPage;
